<template>
  <div class="body">
    <v-card :loading="loading" class="ma-0 ma-sm-4 d-flex flex-grow-1 flex-column">
      <div class="flex-grow-1 flex-sm-grow-0 forms-content overflow-auto">
        <h3 class="subtitle-1 mb-1 px-4 pt-4">Mantenha Seus dados atualizados</h3>
        <v-divider/>
        <section class="pa-4">
          <v-form ref="form" @submit.prevent="save" v-model="formValid" lazy-validation>
            <MeusDadosForm v-model="item"/>
          </v-form>
        </section>
      </div>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="success" @click="irProPainel" :disabled="loading">Cancelar</v-btn>
        <v-btn color="primary" @click="save" :disabled="!formValid || loading">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import {findOne, update} from "../../api/contato";
import MeusDadosForm from "../../components/shared/forms/MeusDadosForm";
import {ApiError} from "../../api/core";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "index",
  components: { MeusDadosForm },
  data () {
    return {
      item: {},
      formValid: false,
      loading: false,
      emailAntigo: ""
    }
  },
  computed: {
    ...mapGetters('auth',['user']),
    getIdResponsavel(){
      return this.user.idResponsavel;
    }
  },
  methods: {
    ...mapActions('auth',['doLogout', 'getDefaultLoginPage']),
    async save() {
      try {
        this.loading = true;
        if(this.$refs.form.validate()) {
          await update(this.item.id, this.item);
          
          this.$emit('input', false);
          this.$emit('onSave');
          this.$toast.success(`Dados atualizados com sucesso!`, {
            timeout: 3000
          });
          if (this.emailAntigo !== this.item.email){
            this.$toast.success(`Você alterou seu e-mail, por isso é necessário efetiar o login novamente.!`, {
              timeout: 3000
            });
            setTimeout(() => {
              this.irPraTelaLogin();
            }, 2000);
          }
        }
      } catch (err) {
        if (err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível alterar a Senha.`, {
            timeout: 3000
          })
        }        
      } finally {
        this.loading = false;
      }
    },
    async getDadosResponsavel(id) {
      try {
        this.loading = true;
        const page = await findOne(id);

        this.item = page.dados[0];
        this.emailAntigo = this.item.email;

      } catch (err) {
        this.elements = [];        
        if (err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível carregar seus dados.`, {
            timeout: 6000
          })
        }   
      } finally {
        this.loading = false;
      }
    },
    async irPraTelaLogin() {
      await this.doLogout();
    },
    irProPainel() {
      this.$router.push({ name: 'Painel' });
    }
  },
  mounted() {
    this.getDadosResponsavel(this.getIdResponsavel);
  }
}
</script>

<style scoped lang="scss">

.body {
  width: 100%;
  background-color: white !important; /* Define o fundo branco para todo o componente */
}

::v-deep {
  .v-data-table__wrapper {
    height: calc(100vh - 157px);
  }
}

</style>